<template>
  <list-container>
    <AuctionsList type="lost" :user="user_id" />
  </list-container>
</template>

<script>
import AuctionsList from "@/components/Auctions/AuctionsList";
import ListContainer from "@/components/Auctions/ListContainer";

export default {
  name: "Lost",
  components: { ListContainer, AuctionsList },
  computed: {
    user_id() {
      return this.$route.params.user;
    },
  },
};
</script>
